import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CFormLabel,
  CLoadingButton,
  CRow,
  CAccordion,
  CAccordionItem,
  CAccordionHeader,
  CAccordionBody,
  CFormSwitch,
  CButton,
} from '@coreui/react-pro'
import Notify from '../Notify'
import React, { useEffect, useRef, useState, useMemo } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ToastComponent from 'src/components/common/TaostComponent'
import CommonService from 'src/service/CommonService'
import PreviewImage from '../PreviewImage'
import SponsorService from 'src/service/SponsorService'
import { useNavigate } from 'react-router-dom'

const Branding = () => {
  const [loading, setLoading] = useState()
  const [brandingDetail, setBrandingDetail] = useState({})
  const [showPosBased, setShowPosBased] = useState(0)
  useEffect(() => {
    CommonService.branding().then((res) => {
      if (res.status === 200) {
        setBrandingDetail(res.data)
        setValue(res.data?.about)
        setShowPosBased(res?.data?.is_show)
      }
    })
  }, [])

  const SUPPORTED_FORMATS = ['image/jpg', 'image/png', 'image/jpeg', 'image/gif']
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('name is required').max(50, '50 Character Limit is allowed.'),
    url: Yup.string().required('Website URL is required'),
    image: Yup.mixed()
      .nullable(true)
      .test(
        'fileSize',
        'File size too large, max file size is 10Mb or resolution is not 500px x 500px',
        (file) => {
          if (file) {
            return file.size <= 500 * 500
          } else {
            return true
          }
        },
      )
      .test(
        'type',
        'Invalid file format selection',
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type)),
      ),
  })
  const formik = useFormik({
    initialValues: {
      name: brandingDetail?.name || '',
      url: brandingDetail?.url || '',
      facebook: brandingDetail?.facebook || '',
      twitter: brandingDetail?.twitter || '',
      instagram: brandingDetail?.instagram || '',
      about: brandingDetail?.about || '',
      image: null,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (data, actions) => {
      var formData = new FormData()
      formData.append('name', data.name)
      formData.append('url', data.url)
      formData.append('image', data.image)
      formData.append('facebook', data.facebook)
      formData.append('twitter', data.twitter)
      formData.append('instagram', data.instagram)
      formData.append('about', value)
      formData.append('is_show', showPosBased)
      setLoading(true)
      CommonService.editBranding(formData)
        .then((res) => {
          if (res.status === 200) {
            ToastComponent(res.message, 'success')
            setLoading(false)
          } else {
            setLoading(false)
            ToastComponent(res.message, 'error')
          }
        })
        .catch((e) => {
          ToastComponent('Something went wrong. Please try again.', 'error')
          setLoading(false)
        })
    },
  })

  // Editor code here.
  const [value, setValue] = useState()
  const quillRef = useRef()
  const imageHandler = (e) => {
    const editor = quillRef.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      const file = input.files[0]
      if (/^image\//.test(file.type)) {
        const formData = new FormData()
        formData.append('image', file)
        const res = await SponsorService.imageUplaod(formData) // upload data into server or aws or cloudinary
        const url = res?.url
        editor.insertEmbed(editor.getSelection(), 'image', url)
      } else {
        ToastComponent('You could only upload images.', 'error')
      }
    }
  }
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          ['image', 'link'],
          [
            {
              color: [
                '#000000',
                '#e60000',
                '#ff9900',
                '#ffff00',
                '#008a00',
                '#0066cc',
                '#9933ff',
                '#ffffff',
                '#facccc',
                '#ffebcc',
                '#ffffcc',
                '#cce8cc',
                '#cce0f5',
                '#ebd6ff',
                '#bbbbbb',
                '#f06666',
                '#ffc266',
                '#ffff66',
                '#66b966',
                '#66a3e0',
                '#c285ff',
                '#888888',
                '#a10000',
                '#b26b00',
                '#b2b200',
                '#006100',
                '#0047b2',
                '#6b24b2',
                '#444444',
                '#5c0000',
                '#663d00',
                '#666600',
                '#003700',
                '#002966',
                '#3d1466',
              ],
            },
          ],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    [],
  )
  // Finish here

  const handleChangeStatus = (event) => {
    const data = {}
    if (event.target.checked) {
      setShowPosBased(1)
      data.is_show = 1
    }
    if (!event.target.checked) {
      setShowPosBased(0)
      data.is_show = 0
    }

    CommonService.updateShowBranding(data).then((res) => {
      if (res.status === 200) {
        ToastComponent(res.message, 'success')
        setLoading(false)
      } else {
        setLoading(false)
        ToastComponent(res.message, 'error')
      }
    })
    // setShowPosBased(event.target.checked)
  }

  // Are you sure want modal
  const navigate = useNavigate()
  const [handleYes, setHandleYes] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [handleNo, setHandleNo] = useState(false)
  const handleCancel = () => {
    return setShowConfirm(false)
  }

  const handleConfirm = () => {
    clearBranding()
    return setShowConfirm(false)
  }
  const areYouSureModal = (id) => {
    setShowConfirm(true)
  }

  const clearBranding = () => {
    CommonService.deleteBranding().then((res) => {
      if (res.status === 200) {
        ToastComponent(res.data.message, 'success')
        formik.resetForm()
        formik.setFieldValue('image', null) //
        navigate('/branding')
        setLoading(false)
      }
    })
  }
  return (
    <>
      <CRow>
        <CAccordion activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              {' '}
              <strong>Manage Branding</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CForm className="row g-3" onSubmit={formik.handleSubmit}>
                <CCol md={6}>
                  <CFormLabel className="fw-bold" htmlFor="name">
                    Name *
                  </CFormLabel>
                  <CFormInput
                    className={
                      'form-control' +
                      (formik.errors.name && formik.touched.name ? ' is-invalid' : '')
                    }
                    id="name"
                    value={formik.values.name || ''}
                    name="name"
                    placeholder="Name"
                    onChange={formik.handleChange}
                  />
                  {formik.errors.name && formik.touched.name && (
                    <CFormFeedback invalid>{formik.errors.name}</CFormFeedback>
                  )}
                </CCol>
                <CCol md={6}>
                  <CFormLabel className="fw-bold" htmlFor="youtube2">
                    Website *
                  </CFormLabel>
                  <CFormInput
                    id="url"
                    placeholder="Website"
                    className={
                      'form-control' +
                      (formik.errors.url && formik.touched.url ? ' is-invalid' : '')
                    }
                    value={formik.values.url || ''}
                    name="url"
                    onChange={formik.handleChange}
                  />
                  {formik.errors.url && formik.touched.url && (
                    <CFormFeedback invalid>{formik.errors.url}</CFormFeedback>
                  )}
                </CCol>
                <CCol md={4}>
                  <CFormLabel className="fw-bold" htmlFor="facebook">
                    Facebook URL
                  </CFormLabel>
                  <CFormInput
                    placeholder="Facebook URL"
                    classfacebook={
                      'form-control' +
                      (formik.errors.facebook && formik.touched.facebook ? ' is-invalid' : '')
                    }
                    value={formik.values.facebook || ''}
                    onChange={formik.handleChange}
                    aria-label="facebook"
                    id="facebook"
                  />
                  <small>
                    <i> (Ex: https://www.facebook.com/myclubtap)</i>
                  </small>
                  {formik.errors.facebook && formik.touched.facebook && (
                    <CFormFeedback invalid>{formik.errors.facebook}</CFormFeedback>
                  )}
                </CCol>
                <CCol md={4}>
                  <CFormLabel className="fw-bold" htmlFor="facebook">
                    Twitter URL
                  </CFormLabel>
                  <CFormInput
                    placeholder="Twitter URL"
                    classtwitter={
                      'form-control' +
                      (formik.errors.twitter && formik.touched.twitter ? ' is-invalid' : '')
                    }
                    value={formik.values.twitter || ''}
                    onChange={formik.handleChange}
                    aria-label="twitter"
                    id="twitter"
                  />
                  <small>
                    <i> (Ex: https://www.twitter.com/myclubtap)</i>
                  </small>
                  {formik.errors.twitter && formik.touched.twitter && (
                    <CFormFeedback invalid>{formik.errors.twitter}</CFormFeedback>
                  )}
                </CCol>
                <CCol md={4}>
                  <CFormLabel className="fw-bold" htmlFor="facebook">
                    Instagram URL
                  </CFormLabel>
                  <CFormInput
                    placeholder="Instagram URL"
                    className={
                      'form-control' +
                      (formik.errors.instagram && formik.touched.instagram ? ' is-invalid' : '')
                    }
                    value={formik.values.instagram || ''}
                    onChange={formik.handleChange}
                    aria-label="instagram"
                    id="instagram"
                  />
                  <small>
                    <i> (Ex: https://www.instagram.com/myclubtap)</i>
                  </small>
                  {formik.errors.instagram && formik.touched.instagram && (
                    <CFormFeedback invalid>{formik.errors.instagram}</CFormFeedback>
                  )}
                </CCol>
                <CCol md={4}>
                  <CFormLabel className="fw-bold" htmlFor="image">
                    Brand Logo (Dimesion: 500 px x 500 px)
                  </CFormLabel>
                  <CFormInput
                    type="file"
                    id="formFile"
                    name="image"
                    className={
                      formik.touched.image
                        ? formik.errors.image
                          ? 'form-control input_user is-invalid'
                          : 'form-control input_user is-valid'
                        : 'form-control'
                    }
                    onChange={(event) => {
                      formik.setTouched({
                        ...formik.touched,
                        image: true,
                      })
                      formik.setFieldValue('image', event.target.files[0])
                    }}
                  />
                  {formik.touched.image && formik.errors.image ? (
                    <CFormFeedback invalid>{formik.errors.image}</CFormFeedback>
                  ) : null}
                  <CRow>
                    <CCol md={4}>
                      <CCol md={4}>
                        {brandingDetail?.logo && (
                          <img
                            src={`${process.env.REACT_APP_API_URL}uploads/branding/${brandingDetail.logo}`}
                            alt="game_logo"
                            width={'100px'}
                            height={'100px'}
                          />
                        )}
                        <br></br>
                        {formik.values.image ? (
                          <PreviewImage
                            className={{ margin: 'auto' }}
                            width={'100px'}
                            height={'100px'}
                            file={formik.values.image}
                          />
                        ) : null}
                      </CCol>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol md={8}>
                  <CFormLabel className="fw-bold" htmlFor="youtube2">
                    About Brand
                  </CFormLabel>
                  <ReactQuill
                    theme="snow"
                    ref={quillRef}
                    value={value}
                    onChange={setValue}
                    modules={modules}
                    style={{ border: '1px solid' }}
                  />
                </CCol>

                <CCol md={6}>
                  <CLoadingButton type="submit" color="success" variant="outline" loading={loading}>
                    Submit
                  </CLoadingButton>
                  &nbsp;
                  <CButton color="danger" variant="outline" onClick={areYouSureModal}>
                    Delete
                  </CButton>
                </CCol>
              </CForm>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
        <CAccordion activeItemKey={1}>
          <CAccordionItem itemKey={1}>
            <CAccordionHeader>
              {' '}
              <strong>Show/Hide Fantasy Partner (Branding)</strong>
            </CAccordionHeader>
            <CAccordionBody>
              <CCol>
                <strong>
                  <CFormSwitch
                    size="lg"
                    label={'Show/Hide'}
                    id="formSwitchCheckDefaultLg"
                    checked={showPosBased ? true : false}
                    onChange={handleChangeStatus}
                  />
                </strong>
              </CCol>
            </CAccordionBody>
          </CAccordionItem>
        </CAccordion>
      </CRow>
      <Notify
        setShowConfirm={setShowConfirm}
        showConfirm={showConfirm}
        setHandleNo={setHandleNo}
        handleNo={handleNo}
        handleYes={handleYes}
        setHandleYes={setHandleYes}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        text="Are you sure you want to delete this?"
      />
    </>
  )
}

export default Branding
